import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'

import '../styles/main.scss'

const index = () => {
  return (
    <>
        <Helmet
        htmlAttributes={{
            class: 'bd-main'
        }}>
            <title>Brolga Digital Client Portal</title>
        </Helmet>
        
        <div className='uk-container uk-container-medium uk-height-viewport uk-flex uk-flex-column uk-flex-around'>
            <div uk-grid='' >
                <div className='uk-width-1-1 uk-width-1-2@s'>
                    <StaticImage src='../images/brolgadigital/brolga-red-01.png' alt=''/>
                </div>
                <div className='uk-padding uk-flex uk-flex-column uk-flex-center uk-width-1-1 uk-width-1-2@s'>
                    <h1 className='uk-text-center uk-text-left@m'>Brolga Digital Client Portal</h1>
                    <form>
                        <div className='uk-margin'>
                            <input className='uk-input' type='text' placeholder='Username'/>
                        </div>
                        <div className='uk-margin'>
                            <input className='uk-input' type='password' placeholder='Password'/>
                        </div>
                        <div className='uk-margin'>
                            <button type='submit' className='uk-button'>Log In</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  )
}

export default index